import React from "react";
import AC from "../assets/AC.jpeg";
import AKrishna from "../assets/AKrishna.jpg";
import SMitra from "../assets/SMitra.jpg";
import SSah from "../assets/SSah.jpg";
import Prashanth from "../assets/Prashanth.jpg";
import ANagar from "../assets/ANagar.jpg";
import RYadav from "../assets/RYadav.jpg";
import YSinha from "../assets/YSinha.jpeg";
import AJaiswal from "../assets/AJaiswal.jpg";
import AChaudhary from "../assets/AChaudhary.png";
import R from "../assets/R.jpg";
import AHossain from "../assets/AHossain.jpg";
import AB from "../assets/AB.JPG";
import SN from "../assets/SN.jpg";
import S from "../assets/S.JPG";
import AK from "../assets/AK.jpg";
import D from "../assets/D.jpg";
import DG from "../assets/DG.jpg";
import SDE from "../assets/SDE.jpg";
import RK from "../assets/RK.jpg";
import SM from "../assets/SM.jpg";
import TTS from "../assets/TTS.jpg";
import T from "../assets/T.jpg";
import NK1 from "../assets/NK1.jpg";
import NK2 from "../assets/NK2.jpg";
import HA from "../assets/HA.jpg";
import anuj from "../assets/anuj.jpg";
import ryt from "../assets/ryt.jpg";
import pandey from "../assets/pandey.jpg";
import samr from "../assets/samr.jpg";
import samn from "../assets/samn.jpg";
import swagata from "../assets/swagata.jpg";
import rao from "../assets/rao.JPG";
import All from '../assets/ALL.jpeg'
import "./Alumni.css";
import NavBar from "../NavBar";
import Footer from "../Footer";
import ZHossain from "../assets/ZHossain.png";
import RChowdhury from "../assets/RChowdhury.jpg";
import SMookherjee from "../assets/SMookherjee.jpg";
import SRajwar from "../assets/SRajwar.jpg";
import ADey from "../assets/ADey.png";
import DBardhan from "../assets/DBardhan.jpg";
import DKher from "../assets/Dkher.jpg";
import SAcharya from "../assets/SAcharya.jpg";
import ANagarajan from "../assets/ANagarajan.jpeg";
function Alumni() {
  return (
    <div>
      <NavBar />
      <div className="container-fluid margin__top">
        <div className="section-title">
          <h1>Our Alumni</h1>
          <hr className="hr" width="50%" style={{ margin: "0 auto" }} />
        </div>
        <div className="outer members__box__wrap">
          <div className="section-title">
            <h3>ChESS Members from Batch of 2020</h3>
          </div>
          <div className="row">
            <div className="col-sm-3">
              <div className="team-5 members__box">
                <div className="team-img">
                  <img src={TTS} alt="Team Img" />
                </div>
                <div className="team-content">
                  <h2 className="membersName">TT Sriram</h2>
                  <h4 className="role glitch">President (2019-20)</h4>
                </div>
              </div>
            </div>
            <div className="col-sm-3">
              <div className="team-5 members__box">
                <div className="team-img">
                  <img src={R} alt="Team Img" />
                </div>
                <div className="team-content">
                  <h2 className="membersName">Ritika Das</h2>
                  <h4 className="role glitch">Vice President (2019-20)</h4>
                </div>
              </div>
            </div>
            <div className="col-sm-3">
              <div className="team-5 members__box">
                <div className="team-img">
                  <img src={NK1} alt="Team Img" />
                </div>
                <div className="team-content">
                  <h2 className="membersName">Naveen Kumar</h2>
                  <h4 className="role glitch">General Secretary (2019-20)</h4>
                </div>
              </div>
            </div>
            <div className="col-sm-3">
              <div className="team-5 members__box">
                <div className="team-img">
                  <img src={T} alt="Team Img" />
                </div>
                <div className="team-content">
                  <h2 className="membersName">Tejaswi Shah</h2>
                </div>
              </div>
            </div>
          </div>
          <div className="row" style={{ justifyContent: "center" }}>
            <div className="col-sm-3">
              <div className="team-5 members__box">
                <div className="team-img">
                  <img src={AK} alt="Team Img" />
                </div>
                <div className="team-content">
                  <h2 className="membersName">Aritra Karmakar</h2>
                </div>
              </div>
            </div>
            <div className="col-sm-3">
              <div className="team-5 members__box">
                <div className="team-img">
                  <img src={RK} alt="Team Img" />
                </div>
                <div className="team-content">
                  <h2 className="membersName">Rakesh Kumar Sisodiya</h2>
                </div>
              </div>
            </div>
            <div className="col-sm-3">
              <div className="team-5 members__box">
                <div className="team-img">
                  <img src={SDE} alt="Team Img" />
                </div>
                <div className="team-content">
                  <h2 className="membersName">Subhadeepa Dey</h2>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="outer members__box__wrap">
          <div className="section-title">
            <h3>ChESS Members from Batch of 2021</h3>
          </div>
          <div className="row">
            <div className="col-sm-3">
              <div className="team-5 members__box">
                <div className="team-img">
                  <img src={DG} alt="Team Img" />
                </div>
                <div className="team-content">
                  <h2 className="membersName">Debjit Goswami</h2>
                  <h4 className="role glitch">President (2020-21)</h4>
                </div>
              </div>
            </div>
            <div className="col-sm-3">
              <div className="team-5 members__box">
                <div className="team-img">
                  <img src={AHossain} alt="Team Img" />
                </div>
                <div className="team-content">
                  <h2 className="membersName">Adil Hossain</h2>
                  <h4 className="role glitch">Vice President (2020-21)</h4>
                </div>
              </div>
            </div>
            <div className="col-sm-3">
              <div className="team-5 members__box">
                <div className="team-img">
                  <img src={HA} alt="Team Img" />
                </div>
                <div className="team-content">
                  <h2 className="membersName">Harshit Anand</h2>
                  <h4 className="role glitch">General Secretary (2020-21)</h4>
                </div>
              </div>
            </div>
            <div className="col-sm-3">
              <div className="team-5 members__box">
                <div className="team-img">
                  <img src={AB} alt="Team Img" />
                </div>
                <div className="team-content">
                  <h2 className="membersName">Boyina Venkata Akhil</h2>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-3">
              <div className="team-5 members__box">
                <div className="team-img">
                  <img src={D} alt="Team Img" />
                </div>
                <div className="team-content">
                  <h2 className="membersName">Darpan Shyamkumar Agarwal</h2>
                </div>
              </div>
            </div>
            <div className="col-sm-3">
              <div className="team-5 members__box">
                <div className="team-img">
                  <img src={S} alt="Team Img" />
                </div>
                <div className="team-content">
                  <h2 className="membersName">Sucharita Pal</h2>
                </div>
              </div>
            </div>
            <div className="col-sm-3">
              <div className="team-5 members__box">
                <div className="team-img">
                  <img src={NK2} alt="Team Img" />
                </div>
                <div className="team-content">
                  <h2 className="membersName">Nishant Kumar</h2>
                </div>
              </div>
            </div>
            <div className="col-sm-3">
              <div className="team-5 members__box">
                <div className="team-img">
                  <img src={SN} alt="Team Img" />
                </div>
                <div className="team-content">
                  <h2 className="membersName">Saurav Nag</h2>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="outer members__box__wrap">
          <div className="section-title">
            <h3>ChESS Members from Batch of 2022</h3>
          </div>
          <div className="row">
            <div className="col-sm-3">
              <div className="team-5 members__box">
                <div className="team-img">
                  <img src={ryt} alt="Team Img" />
                </div>
                <div className="team-content">
                  <h2 className="membersName">Rhythm Aich</h2>
                  <h4 className="role glitch">President (2021-22)</h4>
                </div>
              </div>
            </div>
            <div className="col-sm-3">
              <div className="team-5 members__box">
                <div className="team-img">
                  <img src={SM} alt="Team Img" />
                </div>
                <div className="team-content">
                  <h2 className="membersName">Saumyajeet Mukherjee</h2>
                  <h4 className="role glitch">Vice President (2021-22)</h4>
                </div>
              </div>
            </div>
            <div className="col-sm-3">
              <div className="team-5 members__box">
                <div className="team-img">
                  <img src={samn} alt="Team Img" />
                </div>
                <div className="team-content">
                  <h2 className="membersName">Sammanoy Mukherjee</h2>
                  <h4 className="role glitch">General Secretary (2021-22)</h4>
                </div>
              </div>
            </div>
            <div className="col-sm-3">
              <div className="team-5 members__box">
                <div className="team-img">
                  <img src={swagata} alt="Team Img" />
                </div>
                <div className="team-content">
                  <h2 className="membersName">Swagata Laxmi Sengupta</h2>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-3">
              <div className="team-5 members__box">
                <div className="team-img">
                  <img src={pandey} alt="Team Img" />
                </div>
                <div className="team-content">
                  <h2 className="membersName">Amar Kumar Pandey</h2>
                </div>
              </div>
            </div>
            <div className="col-sm-3">
              <div className="team-5 members__box">
                <div className="team-img">
                  <img src={samr} alt="Team Img" />
                </div>
                <div className="team-content">
                  <h2 className="membersName">Samriddho Saha</h2>
                </div>
              </div>
            </div>
            <div className="col-sm-3">
              <div className="team-5 members__box">
                <div className="team-img">
                  <img src={rao} alt="Team Img" />
                </div>
                <div className="team-content">
                  <h2 className="membersName">BHV Prasad</h2>
                </div>
              </div>
            </div>
            <div className="col-sm-3">
              <div className="team-5 members__box">
                <div className="team-img">
                  <img src={anuj} alt="Team Img" />
                </div>
                <div className="team-content">
                  <h2 className="membersName">Anuj Patel</h2>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="outer members__box__wrap">
          <div className="section-title">
            <h3>ChESS Members from Batch of 2023</h3>
          </div>
          <div className="row">
            <div className="col-sm-3">
              <div className="team-5 members__box">
                <div className="team-img">
                  <img src={SMitra} alt="Team Img" />
                </div>
                <div className="team-content">
                  <h2 className="membersName">Soumyajeet Mitra</h2>
                  <h4 className="role glitch">President (2022-23)</h4>
                </div>
              </div>
            </div>
            <div className="col-sm-3">
              <div className="team-5 members__box">
                <div className="team-img">
                  <img src={SSah} alt="Team Img" />
                </div>
                <div className="team-content">
                  <h2 className="membersName">Sonali Sah</h2>
                  <h4 className="role glitch">Vice President (2022-23)</h4>
                </div>
              </div>
            </div>
            <div className="col-sm-3">
              <div className="team-5 members__box">
                <div className="team-img">
                  <img src={Prashanth} alt="Team Img" />
                </div>
                <div className="team-content">
                  <h2 className="membersName">Prashanth Kumar</h2>
                  <h4 className="role glitch">General Secretary (2022-23)</h4>
                </div>
              </div>
            </div>

            <div className="col-sm-3">
              <div className="team-5 members__box">
                <div className="team-img">
                  <img src={ANagar} alt="Team Img" />
                </div>
                <div className="team-content">
                  <h2 className="membersName">Anand Nagar</h2>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-3">
              <div className="team-5 members__box">
                <div className="team-img">
                  <img src={RYadav} alt="Team Img" />
                </div>
                <div className="team-content">
                  <h2 className="membersName">Rishabh Yadav</h2>
                </div>
              </div>
            </div>
            <div className="col-sm-3">
              <div className="team-5 members__box">
                <div className="team-img">
                  <img src={YSinha} alt="Team Img" />
                </div>
                <div className="team-content">
                  <h2 className="membersName">Yash Sinha</h2>
                </div>
              </div>
            </div>
            <div className="col-sm-3">
              <div className="team-5 members__box">
                <div className="team-img">
                  <img src={AJaiswal} alt="Team Img" />
                </div>
                <div className="team-content">
                  <h2 className="membersName">Ankit Jaiswal</h2>
                </div>
              </div>
            </div>
            <div className="col-sm-3">
              <div className="team-5 members__box">
                <div className="team-img">
                  <img src={AChaudhary} alt="Team Img" />
                </div>
                <div className="team-content">
                  <h2 className="membersName">Ayush Chaudhary</h2>
                </div>
              </div>
            </div>

            <div className="row" style={{ justifyContent: "center" }}>
              <div className="col-sm-3">
                <div className="team-5 members__box">
                  <div className="team-img">
                    <img src={AKrishna} alt="Team Img" />
                  </div>
                  <div className="team-content">
                    <h2 className="membersName">Abhinav Krishna</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="outer members__box__wrap">
          <div className="section-title">
            <h3>ChESS Members from Batch of 2024</h3>
          </div>
        <div className="outer members__box__wrap">



          <div className="row">
           
           <div className="col-sm-4">
              <div className="team-5 members__box">
                <div className="team-img">
                  <img src={SRajwar} alt="Team Img" />
                </div>
                <div className="team-content">
                  <h2 className="membersName">Sushanta Rajwar</h2>
                  <h4 className="role glitch">President</h4>
                </div>
              </div>
            </div>

            <div className="col-sm-4">
              <div className="team-5 members__box">
                <div className="team-img">
                  <img src={RChowdhury} alt="Team Img" />
                </div>
                <div className="team-content">
                  <h2 className="membersName">Riddhi Chowdhury</h2>
                  <h4 className="role glitch">Vice President</h4>
                </div>
              </div>
            </div>

            <div className="col-sm-4">
              <div className="team-5  members__box">
                <div className="team-img">
                  <img src={SMookherjee} alt="Team Img" />
                </div>
                <div className="team-content">
                  <h2 className="membersName">Sujaan Mookherjee</h2>
                  <h4 className="role glitch">General Secretary</h4>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
           
            <div className="row">
            <div className="col-sm-4">
              <div className="team-5 members__box">
                <div className="team-img">
                  <img src={SAcharya} alt="Team Img" />
                </div>
                <div className="team-content">
                  <h2 className="membersName">Sayak Acharya</h2>
                  <h4 className="role glitch">Convenor</h4>
                </div>
              </div>
            </div>

             <div className="col-sm-4">
              <div className="team-5 members__box">
                <div className="team-img">
                  <img src={DKher} alt="Team Img" />
                </div>
                <div className="team-content">
                  <h2 className="membersName">Devesh kher</h2>
                  <h4 className="role glitch">Treasurer</h4>
                </div>
              </div>
             </div>

             <div className="col-sm-4">
              <div className="team-5 members__box">
                <div className="team-img">
                  <img src={DBardhan} alt="Team Img" />
                </div>
                <div className="team-content">
                  <h2 className="membersName">Dev Bardhan Singh</h2>
                  <h4 className="role glitch">Creative Head and Event Management Head</h4>
                </div>
              </div>
             </div>

             <div className="col-sm-4">
              <div className="team-5 members__box">
                <div className="team-img">
                  <img src={ZHossain} alt="Team Img" />
                </div>
                <div className="team-content">
                  <h2 className="membersName">Zeshan Hossain Sardar</h2>
                  <h4 className="role glitch">Publicity and Sponsorship Head</h4>
                </div>
              </div>
             </div>

             <div className="col-sm-4">
              <div className="team-5 members__box">
                <div className="team-img">
                  <img src={ADey} alt="Team Img" />
                </div>
                <div className="team-content">
                  <h2 className="membersName">Anupam Dey</h2>
                  <h4 className="role glitch">Co Convenor and Tech Head</h4>
                </div>
              </div>
             </div>
             <div className="col-sm-4">
              <div className="team-5 members__box">
                <div className="team-img">
                  <img src={ANagarajan} alt="Team Img" />
                </div>
                <div className="team-content">
                  <h2 className="membersName">Anagha Nagarajan</h2>
                  <h4 className="role glitch">Logistics Head</h4>
                </div>
              </div>
            </div>
            </div>
          </div>
        </div>


      </div>
      <Footer />
    </div>
    </div>
  );
}

export default Alumni;
